import '../styles/group-picker.scss';
import React, { useRef } from "react";
import { GuiCheckbox } from './global-ui/gui-checkbox';
import { SearchBox } from './search-box';
import { GuiButton } from './global-ui/gui-button';
import { Scroller } from './scroller';
import { useSelector } from 'react-redux';
import { pluralize, useUpdateState } from '../utilities/utilities';
import actions from '../actions/actions';



export function GroupPicker({ show, className, selected, onDone, onBack }) {
    let isMobile = useSelector(store => store.page.isMobile);
    let loadedItemsRef = useRef();
    let [state, updateState] = useUpdateState({});


    if (show && !state.show && loadedItemsRef.current) {
        // Reset which items are selected
        loadedItemsRef.current.forEach(g => g.selected = selected.some(sel => sel.id === g.id));
        state.checked = [...selected];
    }
    state.show = show;


    function setSearch(search) {
        updateState({ search: search, loading: true });
    }

    async function itemsNeeded(page, pageSize, skipTotal) {
        let result = await actions.groups.load(state.search || "", page, pageSize, skipTotal);
        if (page === 1 && result) {
            let allUsersItem = { id: -1, name: "All Users", userCount: state.allUserCount };
            result.list.unshift(allUsersItem);
            actions.user.getUserCount().then(count => allUsersItem.userCount = count);
        }

        if (result && state.checked) {
            result.list.forEach(g => g.selected = state.checked.some(sel => sel.id === g.id));
        }

        updateState({ loading: false, totalCount: (skipTotal ? state.totalCount : result.totalCount) });
        return result;
    }

    function onItemClick(e) {
        if (e.target.tagName === "DIV") // Otherwise the checkbox's onChange will handle toggling it
        {
            let id = e.currentTarget.getAttribute("data-id");
            onItemChange(parseInt(id));
        }
    }

    function onItemChange(id) {
        let item = loadedItemsRef.current.find(i => i.id === id);
        item.selected = !item.selected;

        let checked = state.checked;

        // All users check/uncheck
        if (id === -1) {
            checked.forEach(g => g.selected = false);
            checked = [];
        }
        else { 
            let allUsers = checked.find(g => g.id === -1);
            if (allUsers) {
                allUsers.selected = false;
                checked = checked.filter(g => g !== allUsers);
            }
        }

        // Item check/uncheck
        if (item.selected)
            checked = checked.concat([item]);
        else
            checked = checked.filter(g => g.id !== id);

        updateState({ checked: checked });
    }


    return (
        <div className={"group-picker " + (show ? " open " : " closed ") + (className || "")}>
            <div className="top-area">
                <div id="back" className="back" onClick={onBack}><i className="far fa-chevron-left"></i>{isMobile ? "" : " Back"}</div>
                <SearchBox onSearchChange={setSearch} searching={state.loading} />
                <GuiButton dataQa="done" onClick={e => onDone(state.checked)}>Done</GuiButton>
            </div>

            <Scroller
                reset={state.search}
                itemHeight={47}
                onItemsNeeded={itemsNeeded}
                loadedItemsRef={loadedItemsRef}
                itemTemplate={item =>
                    <div className={"item" + (item.selected ? " selected" : "") + (item.id === -1 ? " all-users" : "")} onClick={onItemClick} data-id={item.id} key={item.id}>
                        <GuiCheckbox label={item.name} name={item.id} value={item.selected} onChange={onItemChange} />
                        <div className="count">{item.userCount}</div>
                    </div>
                }
            />

            <div className="footer">
                {state.totalCount} {pluralize("Group", state.totalCount)}<span> | {state.checked?.length} Selected</span>
            </div>
        </div>
    );
}