import '../styles/send-alert.scss';
import React, { useMemo, useRef } from 'react';
import { useSelector,  } from 'react-redux';
import actions from '../actions/actions';
import { GuiCheckbox } from '../components/global-ui/gui-checkbox';
import { dispatch } from '../redux/redux-config';
import { AlertType } from '../utilities/constants';
import { formatGridDate, useUpdateState } from '../utilities/utilities';
import GuiHtmlEditor from '../components/global-ui/gui-html-editor';
import * as DOMPurify from "dompurify";
import { GuiTextbox } from '../components/global-ui/gui-textbox';
import { GuiTextArea } from '../components/global-ui/gui-textarea';
import { VoiceRecorder } from '../components/voice-recorder';
import ValidationContext, { useValidation } from '../components/global-ui/validation';
import Error from '../components/global-ui/error';
import { TemplatePickerModal } from '../components/template-picker-modal';
import { TabItem } from '../components/tab-item';
import { GuiButton } from '../components/global-ui/gui-button';
import { ConfirmCloseModal } from '../components/confirm-close-modal';
import { ConfirmDeleteModal } from '../components/confirm-delete-modal';
import { GroupPicker } from '../components/group-picker';
import LoadingSpinner, { Spinner } from '../components/loading-spinner';
import ScheduleAlertModal from '../components/schedule-alert-modal';
import AudioPlayer from '../components/audio-player';
import { useNavState, pushNavState, popNavState } from '../utilities/navigation';
import navigate from '../utilities/navigation';
import ExpandPanel from '../components/expand-panel';




export default function SendAlert() {
    let alert = useSelector(store => store.alert.current);
    let { step, saving } = useSelector(store => store.alert);
    let isMobile = useSelector(store => store.page.isMobile);
    let { pickGroups, confirmClose, pickTemplate, confirmSave, confirmDiscard } = useNavState(loc => loc);

    let initialState = useMemo(() => ({ voiceTab: alert.voiceFileIdentifier && alert.t2sUrl ? "texttospeech" : "record", lastTTSMessage: alert.voiceFileIdentifier ? alert.t2sMessage : null }), []);
    let [state, updateState] = useUpdateState(initialState);
    let emailHtmlEditor = useRef();

    let validation = useValidation(validate, [alert, state]);
    let howTabValid = !validation.getError("tab0");
    let whoTabValid = !validation.getError("tab1");
    let messageTabValid = !validation.getError("tab2");

    alert.isEmergency = useSelector(store => store.router.location.pathname) === "/send/emergency";




    function toggleType(type) {
        dispatch("UPDATE_NEW_ALERT",
            {
                ...alert,
                includeText: false,
                includeEmail: false,
                includeVoice: false,
                [type]: !alert[type]
            });
        updateState({ previewed: false });
    }

    function update(name, value) {
        dispatch("UPDATE_NEW_ALERT", { ...alert, [name]: value });
        updateState({ previewed: false });
    }

    function changeStep(step) {
        if (step > 0 && !howTabValid)
            return;
        if (step > 1 && !whoTabValid)
            return;
        if (step > 2 && !messageTabValid)
            return;
    
        actions.alerts.changeStep(step);
        if (step === 3)
            updateState({ previewed: true});
    }

    function discardAlert() {
        navigate("/");
    }

    function showPopup(popup) {
        pushNavState({ [popup]: true });
    }

    function hidePopup() {
        popNavState();
    }

    function groupsChanged(selected) {
        dispatch("UPDATE_NEW_ALERT", { ...alert, groups: selected });
        updateState({ previewed: false });
        hidePopup();
    }

    function removeGroup(group) {
        dispatch("UPDATE_NEW_ALERT", {
            ...alert,
            groups: alert.groups.filter(g => g !== group)
        });
        updateState({ previewed: false });
    }

    async function convertTTS() {
        if (!state.convertingTTS && alert.t2sMessage) {
            updateState({ convertingTTS: true });
            var result = await actions.alerts.textToSpeech(alert.t2sMessage);
            updateState({ convertingTTS: false, lastTTSMessage: alert.t2sMessage, voiceFileIdentifier: null });

            if (result.success) {
                dispatch("UPDATE_NEW_ALERT", { ...alert, t2sUrl: result.fileUrl });
            }
        }
    }

    function updateVoiceRecording(name, value) {
        dispatch("UPDATE_NEW_ALERT", { ...alert, [name]: value, voiceFileIdentifier: null, voiceFileUrl: null });
        updateState({ previewed: false });
    }

    function onShowTemplatePicker(type) {
        updateState({ templatePickerType: type });
        showPopup("pickTemplate");
    }

    function useTemplate(template) {
        if (!template)
            return;

        let update = { ...alert };
        update.name = template.name;

        if (state.templatePickerType === AlertType.Text) {
            update.smsMessage = template.message;
        }
        else if (state.templatePickerType === AlertType.Email)
        {
            update.emailMessage = template.message;
            emailHtmlEditor.current.setHtml(template.message);
        }
        else if (state.templatePickerType === AlertType.Voice) {
            update.t2sMessage = template.message;
        }

        dispatch("UPDATE_NEW_ALERT", update);
        updateState({ templatePickerType: null, previewed: false });
        hidePopup();
    }

    function onAttachmentAdded(name, info) {
        dispatch("UPDATE_NEW_ALERT", { ...alert, attachments: [...alert.attachments, info] });
        updateState({ previewed: false });
    }

    function removeAttachment(attachment) {
        dispatch("REMOVE_ATTACHMENT", attachment);
        updateState({ previewed: false });
    }

    function scheduleClick() {
        if (!validation.allValid())
            return validation.setShow(true);

        showPopup("confirmSave");
    }

    function saveSchedule(date) {
        hidePopup();
        save(false, date);
    }

    async function save(isDraft, scheduledDate) {
        isDraft = (isDraft === true);

        if (!isDraft && !validation.allValid())
            return validation.setShow(true);


        let newAlert = {
            id: alert.id,
            alertName: alert.name,
            scheduledDate: scheduledDate,
            allGroups: alert.allGroups,
            groups: alert.groups.map(g => g.id),
            priorityLevel: alert.isEmergency ? 10 : 0,
            isDraft: isDraft
        };

        if (alert.includeText) {
            newAlert.isSms = true;
            newAlert.smsMessage = alert.smsMessage;
        }

        if (alert.includeEmail) {
            newAlert.isEmail = true;
            newAlert.emailMessage = alert.emailMessage;
            newAlert.isHtml = true;
            newAlert.attachments = alert.attachments;
            newAlert.replyTo = "noreply@activeschool.net";
            newAlert.emailSenderName = "Local HGS";
        }

        if (alert.includeVoice) {
            newAlert.isVoice = true;
            if (alert.voiceFileIdentifier) {
                newAlert.voiceFileIdentifier = alert.voiceFileIdentifier;
            }
            else if (state.voiceTab === "record")
                newAlert.voiceRecording = alert.voiceRecording;
            else {
                newAlert.ttsMessage = alert.t2sMessage || "";
                newAlert.voiceFileUrl = alert.t2sUrl;
            }
        }

        actions.alerts.saveAlert(newAlert);
    }

    //let nav = useNvigationWarning(nv => {
    //    if (howTabValid && nv.next !== "/successful")
    //        showPopup(isMobile ? "confirmClose" : "confirmDiscard");
    //    else
    //        nv.allow();
    //});

    
    

    return (
        <ValidationContext.Provider value={validation}>

            <div className="send-alert">
                <h2>
                    Send {alert.isEmergency ? "Emergency" : "Standard"} Alert
                    {isMobile && <i className="fal fa-times" onClick={() => howTabValid ? showPopup("confirmClose") : navigate("/")}></i>}
                </h2>

                <div className="work-area">

                    { /* Alert Type */}
                    <ExpandPanel title="How would you like to send it?" expanded={step === 0} complete={howTabValid} onHeaderClick={() => changeStep(0)} hasError={validation.getDisplayError("tab0")}>
                        <div className={"alert-type" + (alert.isEmergency ? " emergency" : "")}>
                            <span className={alert.includeText ? "selected" : ""} onClick={e => toggleType("includeText")}>
                                <i className="fal fa-comment-alt-lines"></i>
                                <span id="text" className="alert-type-text">Text</span>
                            </span>
                            <span className={alert.includeEmail ? "selected" : ""} onClick={e => toggleType("includeEmail")}>
                                <i className="fal fa-envelope"></i>
                                <span id="email" className="alert-type-text">Email</span>
                            </span>
                            <span className={alert.includeVoice ? "selected" : ""} onClick={e => toggleType("includeVoice")}>
                                <i className="fal fa-microphone"></i>
                                <span id="voice" className="alert-type-text">Voice</span>
                            </span>
                        </div>

                        <Error name="alertType" />
                        <div className="next-button-container">
                            <GuiButton dataQa="step1-next" disabled={!howTabValid} onClick={e => changeStep(1)}>Next</GuiButton>
                        </div>
                    </ExpandPanel>


                    { /* Recipients */}
                    <ExpandPanel title="Who do you want to send it to?" expanded={step === 1} complete={whoTabValid} onHeaderClick={() => changeStep(1)} hasError={validation.getDisplayError("tab1")}>
                        {alert.isEmergency &&
                            <GuiCheckbox label="Select All Groups" className="all-groups" name="allGroups" value={alert.allGroups} onChange={update} />
                        }
                        {!alert.allGroups &&
                            <div className="recipient-list">
                                <label>Group List:</label>
                                {alert.groups.map(g =>
                                    <ListItem onRemove={e => removeGroup(g)} key={g.id}>{g.name}</ListItem>
                                )}
                                <button id="group-btn" className="btn btn-outline" onClick={() => showPopup("pickGroups")}><i className="fas fa-plus"></i>Group</button>
                            </div>
                        }
                        <Error name="groups" />

                        <div className="next-button-container">
                            <GuiButton dataQa="step2-done" disabled={!whoTabValid} onClick={e => changeStep(2)}>Next</GuiButton>
                        </div>
                    </ExpandPanel>


                    { /* Create Message */}
                    <ExpandPanel title="Create your message" expanded={step === 2} complete={messageTabValid} onHeaderClick={() => changeStep(2)} hasError={validation.getDisplayError("tab2")}>

                        <div>
                            <GuiTextbox dataQa="subject" className="subject" label="Subject" name="name" value={alert.name} onChange={update} maxLength={100} />
                        </div>

                        {alert.includeText && <>
                            <GuiTextArea dataQa="text-subject" label="Text Message" name="smsMessage" value={alert.smsMessage} onChange={update} maxLength={280} />
                            <button className="choose-template" onClick={() => onShowTemplatePicker(AlertType.Text)}></button>
                        </>}

                        {alert.includeEmail && <>
                            <GuiHtmlEditor id="email-body" label="Body" name="emailMessage" value={alert.emailMessage} onChange={update} onOpenTemplatePicker={() => onShowTemplatePicker(AlertType.Email)} onAttachmentAdded={onAttachmentAdded} fakeRef={emailHtmlEditor} />
                            <div className="attachments">
                                {alert.attachments.map(a =>
                                    <div className="attachment" key={a.url}>
                                        <i className="icon far fa-paperclip"></i>{a.name}<i className="close fal fa-times" onClick={e => removeAttachment(a)}></i>
                                    </div>
                                )}
                            </div>
                        </>}

                        {alert.includeVoice && <>
                            <div className="voice-text-toggle">
                                <div id="voice-recording" className="voice-recording"><TabItem tab="record" currentTab={state.voiceTab} onClick={e => updateState({ voiceTab: "record" })}>Voice Recording</TabItem></div>
                                <div id="text-to-voice" className="text-to-voice"><TabItem tab="texttospeech" currentTab={state.voiceTab} onClick={e => updateState({ voiceTab: "texttospeech" })}>Text to Voice</TabItem></div>
                                {/* Tab for future Callback option */}
                                {/*<div className="text-to-voice"><TablItem tab="callback" currentTab={state.voiceTab} onClick={e => setVoiceTab("callback")}>Callback</TabItem></div>*/}
                            </div>

                            {state.voiceTab === "record" &&
                                <VoiceRecorder name="voiceRecording" value={alert.voiceRecording || alert.voiceFileUrl} onChange={updateVoiceRecording} error={validation.getDisplayError("voiceRecording")} autoPlay={true} />
                            }

                            {state.voiceTab === "texttospeech" &&
                                <div className="text-to-speech">
                                    <GuiTextArea dataQa="body" label={"Body" + (state.lastTTSMessage && state.lastTTSMessage !== alert.t2sMessage ? " *" : "")} name="t2sMessage" value={alert.t2sMessage} onChange={update} onAttachmentAdded={onAttachmentAdded} />
                                    <button className="choose-template" onClick={() => onShowTemplatePicker(AlertType.Voice)}></button>
                                    {!alert.t2sUrl &&
                                        <div className="next-button-container">
                                            <GuiButton dataQa="convert" disabled={!alert.t2sMessage} onClick={convertTTS}>{state.convertingTTS ? <Spinner /> : "Convert to Voice"}</GuiButton>
                                        </div>
                                    }
                                    {alert.t2sUrl && <>
                                        <div className="player">
                                            <AudioPlayer src={alert.t2sUrl} autoPlay={true} />
                                        </div>
                                        <div className="next-button-container">
                                            <GuiButton dataQa="update" className="btn-outline update-tts" disabled={!alert.t2sMessage || state.lastTTSMessage === alert.t2sMessage} onClick={convertTTS}>{state.convertingTTS ? <Spinner /> : "Update"}</GuiButton>
                                            <GuiButton dataQa="use-recording" disabled={!messageTabValid} onClick={e => changeStep(3)}>Use Recording</GuiButton>
                                        </div>
                                    </>}
                                </div>
                            }
                        </>}

                        {(!alert.includeVoice || state.voiceTab !== "texttospeech") &&
                            <div className="next-button-container">
                                <GuiButton dataQa="use-recording" disabled={!messageTabValid} onClick={e => changeStep(3)}>{alert.includeVoice ? "Use Recording" : "Next"}</GuiButton>
                            </div>
                        }
                    </ExpandPanel>


                    { /* Preview */}
                    <ExpandPanel title="Preview and Send" className="preview" expanded={step === 3} complete={state.previewed} onHeaderClick={() => changeStep(3)}>
                        <label>Alert Title</label>
                        <div>{alert.name}</div>
                        {alert.isScheduled &&
                            <>
                                <label>Scheduled For</label>
                                <div>{formatGridDate(alert.scheduledDate)}</div>
                            </>
                        }
                        <label>Recipients</label>
                        <div>{alert.allGroups ? "All Groups" : alert.groups.map(r => r.name).join(", ")}</div>
                        {alert.includeText &&
                            <>
                                <label>Preview Text Message</label>
                                <div>{alert.smsMessage}</div>
                            </>
                        }
                        {alert.includeEmail &&
                            <>
                                <label>Preview Email</label>
                                <div className="box-card">
                                    <div className="box-subject"><label>Subject:</label> {alert.subject}</div>
                                    <div className="box-body" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(alert.emailMessage) }}></div>
                                </div>
                            </>
                        }
                        {alert.includeVoice &&
                            <>
                                <label>Voice Message</label>
                                <AudioPlayer src={state.voiceTab === "record" ? alert.voiceRecording || alert.voiceFileUrl : alert.t2sUrl} />
                            </>
                        }
                    </ExpandPanel>
                </div>

                {/* Buttons */}
                {(isMobile && !alert.isEmergency) ?
                    <div className="bottom-button-bar mobile-three">
                        <GuiButton dataQa="send" className="send" disabled={!state.previewed} onClick={save}>Send</GuiButton>
                        <GuiButton dataQa="save-draft" className="btn-outline save-as" disabled={!howTabValid} onClick={e => howTabValid && save(true)}>Save Draft</GuiButton>
                        <GuiButton dataQa="schedule" className="btn-outline schedule" disabled={!state.previewed} onClick={scheduleClick}>Schedule</GuiButton>
                    </div>
                    :
                    <div className="bottom-button-bar">
                        {!isMobile && <>
                            <GuiButton dataQa="discard" className="btn-outline" onClick={e => showPopup("confirmDiscard")}>Discard</GuiButton>
                        </>}
                        <GuiButton dataQa="save-draft" className="btn-outline save-as" disabled={!howTabValid} onClick={e => howTabValid && save(true)}>Save Draft</GuiButton>
                        <GuiButton dataQa="send" className="send" disabled={!state.previewed} onClick={save}>Send</GuiButton>
                        {!alert.isEmergency &&
                            <GuiButton dataQa="schedule" className="btn-outline schedule" disabled={!state.previewed} onClick={scheduleClick}>Schedule</GuiButton>
                        }
                    </div>
                }
            </div>


            { /* Modals and Popups */}
            <LoadingSpinner show={saving} className="loading-page" />
            <GroupPicker show={pickGroups} selected={alert.groups} onDone={groupsChanged} onBack={hidePopup} />
            {pickTemplate && <TemplatePickerModal alertType={state.templatePickerType} onInsert={useTemplate} onCancel={hidePopup} />}
            {confirmSave && <ScheduleAlertModal value={alert.scheduledDate} onSave={saveSchedule} onCancel={hidePopup} />}
            {confirmClose && <ConfirmCloseModal onDelete={discardAlert} onSaveDraft={e => save(true)} onCancel={hidePopup} />}
            {confirmDiscard && <ConfirmDeleteModal status="discard" onDelete={discardAlert} onCancel={hidePopup} />}

        </ValidationContext.Provider>
    );
}



function ListItem({ children, onRemove }) {
    return <span>{children}<i className="fas fa-times" onClick={onRemove}></i></span>
}

function validate([alert, state]) {
    let errors = {};

    // Alert Type tab
    if (!alert.includeText && !alert.includeEmail && !alert.includeVoice) {
        errors.alertType = "Please choose an alert type.";
        errors.tab0 = true;
    }

    // Recipients tab
    if (!alert.allGroups && alert.groups.length === 0) {
        errors.groups = "Please pick a group to receive your message.";
        errors.tab1 = true;
    }

    // Message tab
    if (!alert.includeText && !alert.includeEmail && !alert.includeVoice)
        errors.tab2 = true;

    if (!alert.name) {
        errors.name = "An alert name is required.";
        errors.tab2 = true;
    }

    if (alert.includeText && !alert.smsMessage) {
        errors.smsMessage = "A text message is required.";
        errors.tab2 = true;
    }

    if (alert.includeEmail && !alert.emailMessage) {
        errors.emailMessage = "An email body is required.";
        errors.tab2 = true;
    }

    if (alert.includeVoice) {
        if (state.voiceTab === "record" && !alert.voiceRecording && !alert.voiceFileIdentifier) {
            errors.voiceRecording = "A voice recording is required.";
            errors.tab2 = true;
        }

        if (state.voiceTab !== "record" && !alert.t2sUrl) {
            errors.voiceRecording = "A recording is required.";
            errors.tab2 = true;
        }
    }

    return errors;
}