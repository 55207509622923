import api from "../api/api";
import { appState, dispatch } from "../redux/redux-config";
import navigate from "../utilities/navigation";
import { isInstalled } from "../utilities/utilities";


const user = {
    login: async (username, password) => {
        dispatch("LOGIN_STARTED", true);

        let installed = isInstalled() || undefined;
        let result = await api.authentication.login(username, password, installed);

        if (result.success) {
            dispatch("LOAD_USER", result);
            if (installed)
                localStorage.setItem("session", result.jwt);
            else
                sessionStorage.setItem("session", result.jwt);
        }
        else {
            dispatch("LOGIN_ERROR", result);
        }
    },

    getUserInfo: async () => {
        let user = appState().user;
        if (user.loaded === undefined && user.token) {
            dispatch("LOAD_USER_INFO", { loaded: false });

            let result = await api.user.getUserInfo(user.token);
            if (result.success) {
                result.loaded = true;
                dispatch("LOAD_USER_INFO", result);
            }
            else {
                dispatch("LOGIN_ERROR", result);
            }
        }
    },

    getUserCount: async () => {
        let token = appState().user.token;
        let result = await api.user.getUserCount(token);
        return (result.count || "-");
    },

    forgot: async (username) => {
        dispatch("LOGIN_STARTED", true);

        let result = await api.authentication.forgot(username);

        if (result.success) {
            dispatch("LOGIN_STARTED", false);
            dispatch("SWITCH_LOGIN_SCREEN", "verificationCode");
            dispatch("LOGIN_SETTINGS", { resetUsername: username });
        }
        else {
            dispatch("LOGIN_ERROR", result);
        }
    },

    verify: async (code) => {
        let resetUsername = appState().user.resetUsername;
        dispatch("LOGIN_STARTED", true);

        let result = await api.authentication.verify(resetUsername, code);

        if (result.success) {
            dispatch("LOGIN_STARTED", false);
            dispatch("LOGIN_SETTINGS", { resetUsername: null, resetId: result.resetToken });
            dispatch("SWITCH_LOGIN_SCREEN", "resetPassword");
        }
        else {
            dispatch("LOGIN_ERROR", result);
        }
    },

    resetPassword: async (password) => {
        let resetId = appState().user.resetId;
        dispatch("LOGIN_STARTED", true);

        let result = await api.authentication.resetPassword(resetId, password);
        if (result.success) {
            dispatch("LOAD_USER", result);
            sessionStorage.setItem("session", result.jwt);
            navigate("/");
        }
        else {
            dispatch("LOGIN_ERROR", result);
        }
    },

    changeScreen: function (screen) {
        dispatch("SWITCH_LOGIN_SCREEN", screen);
    },

    getShortTermToken: async function () {
        let token = appState().user.token;
        let result = await api.authentication.getShortTermToken(token);
        if (result.success)
            return result.token;
    },

    logout: function () {
        sessionStorage.removeItem("session");
        localStorage.removeItem("session");
        dispatch("LOGOUT");
    },

    loadUserTypes: function () {
        let state = appState().user;
        if (state.userTypeOptions)
            return state.userTypeOptions;

        if (!state.sync.loadingUserTypes) {
            state.sync.loadingUserTypes = true;

            api.user.getUserTypes(state.token).then(result => {
                if (result.success) {
                    state.sync.loadingGroupOptions = false;
                    dispatch("USER_SETTINGS", { userTypeOptions: result.list.map(g => ({ value: g.id, label: g.name })) })
                }
            });
        }
        return null;
    },
}
export default user;





export function userReducer(state, action) {
    if (state === undefined)
        return initialState;

    //if (action.type.indexOf("@@") !== 0) {
    //    console.log(action)
    //}

    switch (action.type) {
        case 'LOGIN_STARTED':
            return {
                ...state,
                loggingIn: action.payload
            }

        case 'LOAD_USER':
            return {
                ...state,
                token: action.payload.jwt,
                loggingIn: false,
                resetId: undefined
            }

        case 'LOAD_USER_INFO':
            return {
                ...state,
                loaded: true,
                ...action.payload
            }

        case 'LOGIN_ERROR':
            return {
                ...state,
                loggingIn: false
            }

        case 'LOGIN_SETTINGS':
        case 'USER_SETTINGS':
            return {
                ...state,
                ...action.payload
            }

        case 'SWITCH_LOGIN_SCREEN':
            return {
                ...state,
                switchLoginScreen: action.payload,
            }

        case 'LOGOUT':
            return {
                ...initialState,
                switchLoginScreen: "login",
                token: null
            }

        default:
            return state;
    }
}

const initialState = {
    token: (isInstalled() ? localStorage.getItem("session") : sessionStorage.getItem("session")),
    loaded: undefined,
    switchLoginScreen: (window.location.pathname === "/reset" ? "resetPassword" : "login"),
    sync: {}
};