import api from "../api/api";
import { appState } from "../redux/redux-config";


const contacts = {
    load: async (search, page, pageSize, skipTotal) => {
        let token = appState().user.token;
        let result = await api.contacts.list(token, search, page, pageSize, skipTotal);
        if (result.success) {
            return {
                list: result.contacts.map(c => ({ id: c.id, name: c.firstName + (c.middleName ? " " + c.middleName : "") + " " + c.lastName })),
                totalCount: result.totalCount
            };
        }
    }
}
export default contacts;