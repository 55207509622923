import { alerts } from "./alerts-api";
import { authentication } from "./authentication-api";
import { contacts } from "./contacts-api";
import { groups } from "./groups-api";
import { people } from "./people-api";
import { report } from "./report-api";
import { user } from "./user-api";
import { file } from "./file-api";
import { showToast } from "../components/toast";
import { dispatch } from "../redux/redux-config";

export const api = {
    alerts: alerts,
    authentication: authentication,
    contacts: contacts,
    file: file,
    groups: groups,
    people: people,
    report: report,
    user: user
};
export default api;


export function apiRequestWithChecks(method, url, headers, body) {
    return apiRequest(method, url, headers, body)
        .then(result => {
            if (result.unauthorized) {
                sessionStorage.removeItem("session");
                showToast("error", "Session expired.");
                dispatch("LOGOUT");
            }
            else if (!result.success) {
                showToast("error", result.errorMessage);
            }
            return result;
        })
        .catch(error => {
            showToast("error", error.errorMessage);
            return error;
        });
}


export function apiRequest(method, url, headers, body) {
    headers = headers || {};
    headers["Content-Type"] = headers["Content-Type"] || "application/json";

    if (body && typeof (body) !== "string")
        body = JSON.stringify(body);

    return apiRequestRaw(method, url, headers, body);
}



export function apiRequestRaw(method, url, headers, blob) {
    let success = false;
    headers = headers || {};
    headers["X-HGS-VERSION"] = window.hgsVersion;

    return fetch(url, {
        method: method,
        headers: headers,
        body: blob
    })
        .then(result => {
            // Unauthorized
            if (result.status === 401)
                return { success: false, unauthorized: true, errorMessage: "Unauthorized." };

            let version = result.headers.get("x-version");
            if (version && version !== window.hgsVersion && version !== window.hgsUpdateAvailable) {
                window.hgsUpdateAvailable = version;
                dispatch("UPDATE_AVAILABLE", version);
            }

            //// Retry gateway timeouts, while the app is starting up
            //if (result.status === 504) {
            //    return new Promise((resolve, reject) => {
            //        setTimeout(function () {
            //            apiRequestRaw(method, url, headers, blob)
            //                .then(result => {
            //                    success = true;
            //                    resolve(result)
            //                })
            //                .catch(reject);
            //        }, 4000);
            //    });
            //}

            success = result.ok;
            return result.json();
        })
        .then(json => {
            if (success)
                return json;
            else
                return { ...json, success: false, errorMessage: json.errorMessage || "Error communicating with server." };
        })
        .catch(error => {
            console.error(error);
            return { success: false, errorMessage: error.errorMessage || "Error communicating with server.", error };
        });
}

export function fileUpload(url, token, file, progress_callback, maxFileSizeMB) {
    const progress = {
        id: Math.random(),
        name: file.name,
        size: file.size,
        type: file.type,
        percent: 0,
        complete: false,
        result: null,
        file: file,
        cancel: function () {
            xhr.abort();
        }
    };

    maxFileSizeMB = maxFileSizeMB || 40;
    if (file.size > (maxFileSizeMB * 1000 * 1000)) {
        progress.complete = true;
        progress.result = { success: false, errorMessage: "File size too large. File must be below " + maxFileSizeMB + "MB." };
        progress_callback(progress);
        return;
    }

    const index = file.name.lastIndexOf('.');
    const extension = index > 0 ? file.name.substr(index + 1).toLowerCase() : null;
    if (["jpg", "jpeg", "tiff", "tif", "bmp", "png", "doc", "docx", "pdf", "ppt", "pptx", "xls", "xlsx", "zip", "mp3", "3gp", "mp4", "avi", "mov", "wav", "m4a", "svg"].indexOf(extension) < 0) {
        progress.complete = true;
        progress.result = { success: false, errorMessage: "File type not accepted. (" + extension + ")" };
        progress_callback(progress);
        return;
    }


    // Set up the web request
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", function (e) {
        progress.percent = (e.loaded / e.total * 100);
        progress_callback(progress);
    }, false);

    xhr.onreadystatechange = function (e) {
        if (xhr.readyState == XMLHttpRequest.DONE) {
            progress.complete = true;
            progress.result = (xhr.responseText.indexOf('{') === 0 ? JSON.parse(xhr.responseText) : { success: false, message: xhr.responseText });
            progress_callback(progress);
        }
    };

    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-FILENAME", encodeURIComponent(file.name));
    xhr.setRequestHeader("X-HGS-VERSION", window.hgsVersion);

    if (token)
        xhr.setRequestHeader("Authentication", "Bearer " + token);

    progress_callback(progress);
    xhr.send(file);
}