import "../../styles/global-ui/gui-textbox.scss";
import React, { useContext, useState } from "react";
import ValidationContext from './validation';
import Error from "./error";


export function GuiTextbox({ name, label, value, onChange, disabled, className, style, onKeyUp, error, type, maxLength, format, dataQa }) {
    let validation = useContext(ValidationContext);
    let [inFocus, setInFocus] = useState(false);
    error = error || (validation && validation.show && validation.getDisplayError(name));

    function onTextChange(e) {

        let cleanValue = e.target.value;
        if (format && cleanValue) {
            // Remove format and re-apply
            cleanValue = reformat(e.target);
        }


        if (onChange)
            onChange(name, cleanValue);
    }

    return (
        <div className={"gui-textbox " + (className || "") + (value ? " has-value" : "") + (inFocus ? " in-focus" : "") + (disabled ? " disabled" : "") + (error ? " has-error" : "")}>
            <input
                type={format === "phone" ? "tel" : (type || "text")}
                inputMode={format === "phone" || format === "digits" ? "numeric" : "text"}
                name={name}
                value={formatText(value, format, inFocus)}
                onBeforeInput={onBeforeInput}
                onChange={onTextChange}
                onFocus={e => setInFocus(true)}
                onBlur={e => setInFocus(false)}
                onKeyUp={onKeyUp}
                disabled={disabled || false}
                maxLength={maxLength}
                style={style}
                data-qa={dataQa}
                data-format={format}
            />
            {label && <label className="control-label">{label}</label>}
            <Error error={error} />
        </div>
    );
}



// Formatting Functions
function onBeforeInput(e) {
    if (!e.data)
        return;

    let format = e.target.getAttribute("data-format");
    if (format === "phone") {
        let length = e.target.value.length;
        if (length >= 14 && (e.target.selectionEnd - e.target.selectionStart === 0))
            e.preventDefault();

        let letter = e.data;
        if (letter && /[^0-9]/.test(letter))
            e.preventDefault();
    }
    else if (format === "date") {
        let letter = e.data;
        if (letter && /[^0-9/]/.test(letter))
            e.preventDefault();
    }
    else if (format === "digits") {
        let letter = e.data;
        if (letter && /[^0-9]/.test(letter))
            e.preventDefault();
    }
}

function reformat(input) {
    let format = input.getAttribute("data-format");
    let text = input?.value || "";

    // Remove format and re-apply
    if (format && text) {
        if (format === "phone") {
            text = input.value.replaceAll(/[^0-9]/g, "");

            if (input === document.activeElement) {
                let cursor = input.selectionStart;
                input.value = formatText(text, format, true);

                if (cursor > 0 && input.value[cursor - 1] === ')')
                    cursor += 2;
                if (cursor > 0 && (input.value[cursor - 1] === '(' || input.value[cursor - 1] === '-'))
                    cursor++;
                input.setSelectionRange(cursor, cursor);
            }
            else
                input.value = formatText(text, format, false);
        }
        else if (format === "digits") {
            text = input.value.replaceAll(/[^0-9]/g, "");
            input.value = formatText(text, format);
        }
    }

    return text;
}


function formatText(text, format, inFocus) {
    if (!text)
        return "";

    if (format === "phone") {
        let digits = text.replaceAll(/[^0-9]/g, "");
        if (inFocus)
            return (digits.length > 0 ? "(" : "") + text.substr(0, 3) + (digits.length > 3 ? ") " + digits.substr(3, 3) : "") + (digits.length > 6 ? "-" + digits.substr(6, 4) : "");
        else
            return "(" + digits.substr(0, 3).padEnd(3, '#') + ') ' + digits.substr(3, 3).padEnd(3, '#') + '-' + digits.substr(6, 4).padEnd(4, '#');
    }
    else if (format === "digits") {
        return text.replaceAll(/[^0-9]/g, "");
    }
    return text;
}