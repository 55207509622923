import { apiRequestWithChecks } from "./api";


export const user = {
    getUserInfo: function (token) {
        return apiRequestWithChecks("GET", "/api/user", {
            "Authentication": "Bearer " + token
        });
    },

    getUserCount: function (token) {
        return apiRequestWithChecks("GET", "/api/user/count", {
            "Authentication": "Bearer " + token
        });
    },

    getUserTypes: function (token) {
        return apiRequestWithChecks("GET", "/api/user/types", {
            "Authentication": "Bearer " + token
        });
    },
}