import { apiRequestWithChecks } from "./api";


export const groups = {
    list: function (token, search, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("GET", "/api/groups/list?search=" + encodeURIComponent(search) + "&pageNum=" + pageNum + "&pageSize=" + pageSize + "&skipTotal=" + skipTotal, {
            "Authentication": "Bearer " + token
        });
    },

    getGroupGrid: function (token, search, locations, groupTypes, sort, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("PUT", "/api/groups/grid", {
            "Authentication": "Bearer " + token
        },
            {
                Search: search || "",
                Locations: locations?.map(i => parseInt(i)) || null,
                GroupTypes: groupTypes?.map(i => parseInt(i)) || null,
                Sort: sort,
                PageNum: pageNum,
                PageSize: pageSize,
                SkipTotal: skipTotal
            });
    },

    getGroupOptions: function (token) {
        return apiRequestWithChecks("GET", "/api/groups/options", {
            "Authentication": "Bearer " + token
        });
    },

    getLocations: function (token) {
        return apiRequestWithChecks("GET", "/api/groups/locations", {
            "Authentication": "Bearer " + token
        });
    },

    getGroupTypes: function (token) {
        return apiRequestWithChecks("GET", "/api/groups/types", {
            "Authentication": "Bearer " + token
        });
    },

    getGroupDetails: function (token, id) {
        return apiRequestWithChecks("GET", "/api/groups/details?id=" + id, {
            "Authentication": "Bearer " + token
        });
    },

    save: function (token, group) {
        return apiRequestWithChecks("PUT", "/api/groups", {
            "Authentication": "Bearer " + token
        }, {
            Group: group
        });
    },

    merge: function (token, data) {
        return apiRequestWithChecks("PUT", "/api/groups/merge", {
            "Authentication": "Bearer " + token
        }, data);
    },

    deleteGroups: function (token, groupIds) {
        return apiRequestWithChecks("DELETE", "/api/groups", {
            "Authentication": "Bearer " + token
        },
            {
                GroupIds: groupIds
            });
    },
}