import { apiRequestRaw, apiRequestWithChecks, fileUpload } from "./api";


export const file = {

    uploadRecording: function (token, audioBlob, mimeType) {
		return apiRequestRaw("POST", "/api/file/recording?mimeType=" + encodeURIComponent(mimeType), {
			"Authentication": "Bearer " + token
		}, audioBlob);
	},

	textToSpeech: function (token, message) {
		return apiRequestWithChecks("POST", "/api/file/text-to-speech", {
			"Authentication": "Bearer " + token
		}, { message: message });
    },

    getLibraryItems: function (token) {
        return apiRequestWithChecks("GET", "/api/file/library", {
            "Authentication": "Bearer " + token
        });
    },

    saveLibraryItem: function (token, item) {
        return apiRequestWithChecks("PUT", "/api/file/library", {
            "Authentication": "Bearer " + token
        }, item);
    },

    deleteLibraryItem: function (token, itemId) {
        return apiRequestWithChecks("DELETE", "/api/file/library?itemId=" + itemId, {
            "Authentication": "Bearer " + token
        });
    },

    duplicateLibraryItem: function (token, itemId) {
        return apiRequestWithChecks("DELETE", "/api/file/library/duplicate?itemId=" + itemId, {
            "Authentication": "Bearer " + token
        });
    },

    uploadAttachment: function (token, file, progress_callback, maxFileSizeMB) {
        fileUpload("/api/file/attachment", token, file, progress_callback, maxFileSizeMB);
    },

    uploadBase64Item: function (token, filename, data, tag, overwriteId) {
        return apiRequestWithChecks("POST", "/api/file/base64?tag=" + tag + (overwriteId ? "&overwrite=" + overwriteId : ""), {
            "Authentication": "Bearer " + token,
            "X-FILENAME": encodeURIComponent(filename),
            "Content-Type": "image/png"
        }, data);
    },

    upload: function (url, token, file, progress_callback, maxFileSizeMB) {
        fileUpload(url, token, file, progress_callback, maxFileSizeMB)
    }
}
