import { useState } from 'react';

export function formatDate(date) {
    if (!(date instanceof Date)) {
        date = parseDate(date);
        if (!date)
            return "";
    }
    return date.toLocaleDateString().replace(/\u200E/g, ''); // \u200E is for IE11
}

export function formatGridDate(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
        if (!date)
            return "";
    }

    return date.toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });
}

export function formatDateLong(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
        if (!date)
            return "";
    }

    return date.toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
}

export function formatDateLongScheduled(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
        if (!date)
            return "";
    }

    return date.toLocaleString(undefined, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' });
}

export function formatMonthDay(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
        if (!date)
            return "";
    }

    return date.toLocaleString(undefined, {month: 'long', day: 'numeric'});
}

export function formatTime(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
        if (!date)
            return "";
    }

    return date.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' });
}


const dateFormat = new Date(2000, 10, 22).toLocaleDateString().replace("2000", "YYYY").replace("11", "MM").replace("22", "DD").replace(/\u200E/g, ''); // month 10 is novmember because javascript, \u200E is a left-to-right character that IE11 inserts
const browserMonthFirst = dateFormat.indexOf("MM") === 0;
export function parseDate(val, legacy = false) {
    if (!val)
        return null;

    if (typeof (val) === "string") {
        val = val.trim();
        if (val.indexOf('T') >= 0)
            val = val.substring(0, val.indexOf('T'));

        const parts = val.split(/[./-]/);
        if (parts.length !== 3)
            return null;

        let y, m, d;
        if (parts[0].length >= 3)
            [y, m, d] = parts.map(p => parseInt(p));
        else if (browserMonthFirst || (legacy && val.indexOf("/") > 0))
            [m, d, y] = parts.map(p => parseInt(p));
        else
            [d, m, y] = parts.map(p => parseInt(p));

        if (m === 0 || m > 12)
            return null;
        if (d === 0 || d > 31 || (d > 28 && d > daysInMonth(m, y)))
            return null;
        return new Date(y, m - 1, d);
    }
    else if (!(val instanceof Date))
        val = new Date(val);

    if (isNaN(val.getTime()))
        return null;
    return new Date(val.getFullYear(), val.getMonth(), val.getDate());
}

export function daysInMonth(month, year) {
    // Pass in the calendar month (2 for Februrary)
    return new Date(year, month, 0).getDate();
}

export function formatDateIso(date) {
    if (!(date instanceof Date)) {
        date = parseDate(date);
        if (!date)
            return "";
    }
    return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
}


export function formatDateTimeIso(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
        if (!date)
            return "";
    }
    // 2022-09-09T00:00:00
    return date.getFullYear() + "-" + twoDigit(date.getMonth() + 1) + '-' + twoDigit(date.getDate()) + "T" + twoDigit(date.getHours()) + ":" + twoDigit(date.getMinutes()) + ":" + twoDigit(date.getSeconds());
}

function twoDigit(num) {
    return ("0" + num).slice(-2);
}



export function useUpdateState(init) {
    const [state, setState] = useState(init);

    function update(name, value) {
        if (typeof name === "string")
            setState(s => ({ ...s, [name]: value }));
        else
            setState(s => ({ ...s, ...name }));
    }

    return [state, update, setState];
}

export function isTouchDevice() {
    return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
}

export function preventDefault(e) {
    e.preventDefault();
}

export function isInstalled() {
    return navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;
}

export function pluralize(text, count) {
    if (count !== 1) {
        if (text === "Person")
            return "People";
        if (text === "Activity")
            return "Activities";
        return text + "s";
    }
    return text;
}

export function getInitials(firstName, lastName) {
    return (firstName || "").substr(0, 1).toUpperCase() + (lastName || "").substr(0, 1).toUpperCase()
}


let debounceTime = new Date();
export function debounce(action, timeout) {
    timeout = timeout || 500;
    if (new Date().getTime() - debounceTime.getTime() > timeout) {
        debounceTime = new Date();
        action();
    }
}

export function downloadFile(downloadUrl) {
    const isSafari = navigator.userAgent.toLowerCase().indexOf("safari") >= 0 && navigator.userAgent.toLowerCase().indexOf("chrome") < 0
    if (isSafari) {
        window.location = downloadUrl;
    } else {
        window.open(downloadUrl, '_blank');
    }
}

export function padLeft(val, length, char) {
    val = val + "";
    while (val.length < length)
        val = char + val;
    return val;
}

export function onlyDigits(input) {
    return (input ||  "").replace(/\D/g, '');
}

export function formatPhone(phone) {
    phone = onlyDigits(phone);
    if (phone.length >= 10)
        return phone.substring(0, phone.length - 7) + "-" + phone.substring(-7, 3) + "-" + phone.substr(-4);
    if (phone.length === 7)
        return phone.substring(-7, -4) + "-" + phone.substr(-4);
    return phone;
}