import '../styles/groups.scss';
import React, { useMemo } from 'react';
import PageLayout from '../components/page-layout';
import Card from '../components/card';
import { GuiButton } from '../components/global-ui/gui-button';
import actions from '../actions/actions';
import { SearchBox } from '../components/search-box';
import Popover from '../components/popover';
import { CheckBoxHeaderButton, GuiCheckbox } from '../components/global-ui/gui-checkbox';
import Grid, { ColumnHeader, GridCell } from '../components/grid';
import { FilterDropdown } from '../components/filter-dropdown';
import { useOnFirstLoad } from '../utilities/hooks';
import { useSelector } from 'react-redux';
import { downloadFile, pluralize, useUpdateState } from '../utilities/utilities';
import { Modal } from '../components/modal';
import { EditMultiple } from '../components/edit-multiple';
import { ColumnPicker } from '../components/column-picker';
import { Icon, SeparatorDot } from '../components/icon';
import { FilterTagBar } from '../components/filter-tag-bar';
import { MergeGroupsModal } from '../components/merge-groups-modal';
import { dispatch } from '../redux/redux-config';
import { storage } from '../utilities/storage';
import { GroupPropertiesModal } from '../components/group-properties';


export default function GroupsPage() {

    useOnFirstLoad(actions.groups.initPage);
    let { locationOptions, gridColumns, groupSettingsForUser } = useSelector(store => store.groups);
    let groupTypeOptions = useSelector(store => actions.groups.loadGroupTypes()) || [];
    let isMobile = useSelector(store => store.page.isMobile);
    let [checkboxState, updateCheckboxState, setCheckboxState] = useUpdateState({});
    let locationName = groupSettingsForUser ? (groupSettingsForUser.locationName || "Location") : "";

    let [state, updateState] = useUpdateState(() => {
        return {
            search: "",
            filters: [],
            sort: {},
            gridReset: 0,
            editMultipleItems: [
                { name: "Merge Groups" },
                { name: "Delete Groups" }
            ]
        }
    });

    let filterOptions = [
        { name: locationName, options: locationOptions || [] },
        { name: "Group Type", options: groupTypeOptions || [] },
    ];

    let selectedRows = Object.keys(checkboxState).filter(k => checkboxState[k]);
    let { sort } = state;


    gridColumns = gridColumns || [];
    let visibility = useMemo(() => {
        let vis = {};
        gridColumns.forEach(c => vis[c.column] = c.show);
        return vis;
    }, [gridColumns]);


    async function loadRows(page, pageSize, skipTotal) {
        let sort = Object.keys(state.sort).map(col => ({ column: col, asc: state.sort[col] })).filter(s => s.asc !== undefined).map(s => s.column + " " + (s.asc ? "asc" : "desc")).join(",");

        let locations = state.filters.filter(f => f.name === "Location").map(f => f.value);
        let groupTypes = state.filters.filter(f => f.name === "Group Type").map(f => f.value);

        updateState({ loading: true });

        let result = await actions.groups.getGroupGrid(state.search, locations, groupTypes, sort, page, pageSize, skipTotal);

        updateState({ loading: false, latestPage: result.list });
        return result;
    }

    function setSearch(search) {
        updateState({ search: search, gridReset: state.gridReset + 1 });
    }

    function onFilterChange(action, values) {
        if (action === "clear")
            updateState({ filters: [], gridReset: state.gridReset + 1 });
        else {
            let newFilters = state.filters.concat(values);
            updateState({ filters: newFilters, gridReset: state.gridReset + 1 });
        }
    }

    function removeFilter(filter) {
        updateState({ filters: state.filters.filter(f => f !== filter), gridReset: state.gridReset + 1 });
    }

    function onSort(column) {
        let currentDirection = state.sort[column];
        let nextDirection = currentDirection === true ? false : currentDirection === false ? undefined : true;
        updateState({ sort: { ...state.sort, [column]: nextDirection }, gridReset: state.gridReset + 1 });
    }

    function onPageChanged(list) {
        setCheckboxState({});
        updateState({ currentPage: list });
    }

    function onEditMultipleClick(item) {
        if (item.name === "Merge Groups")
            updateState({ showMergeGroups: true, currentGroup: null });
        else if (item.name === "Delete Groups")
            updateState({ showDeleteGroupsModal: true, currentGroup: null });
    }

    function onColumnChange(item, show) {
        let cols = [...gridColumns];
        let i = cols.findIndex(c => c.name === item.name);
        cols[i] = { ...cols[i], show: show };

        let list = cols.filter(c => c.show).map(c => c.column);
        storage.setObject("groups-columns", list);

        dispatch("GROUP_SETTINGS_UPDATE", { gridColumns: cols });
    }

    function toggleAllCheckboxes() {
        let any = Object.keys(checkboxState).find(k => checkboxState[k]);
        if (any || !state.currentPage)
            setCheckboxState({});
        else
            setCheckboxState(state.currentPage.reduce((newState, row) => { newState[row.id] = true; return newState; }, {}));
    }

    // Actions
    function goToSendAlert(groupId) {
        let groupIds = groupId ? [groupId] : selectedRows;
        let groups = state.currentPage.filter(g => groupIds.includes(g.id));
        actions.alerts.showCreateAlert(false, groups);
    }

    async function deleteGroups() {
        let groups = (state.currentGroup ? [state.currentGroup.id] : selectedRows);
        updateState("saving", true);
        let result = await actions.groups.deleteGroups(groups);
        if (result.success) {
            updateState({ saving: false, showDeleteGroupsModal: false, currentGroup: null, gridReset: state.gridReset + 1 });
            dispatch("GROUP_LIST_CHANGED");
        } else
            updateState({ saving: false });
    }

    async function exportGroup() {
        let token = await actions.user.getShortTermToken();
        if (token) {
            downloadFile("/api/groups/export?id=" + state.currentGroup.id + "&token=" + encodeURIComponent(token));
            updateState("showExportModal", false);
        }
    }


    return (
        <PageLayout
            title="Groups"
            className="groups"
            buttonBar={<>
                <GuiButton dataQa="add-person-button" className="" onClick={e => updateState({ showEditModal: true, currentGroup: {} })}><Icon icon="plus" weight="400" />Create Group</GuiButton>
            </>}
            onMobileSearch={setSearch}
            searching={state.loading}
        >
            <Card>
                {/* Desktop Search bar */}
                {!isMobile &&
                    <div className="page-layout-content-header">
                        <SearchBox onSearchChange={setSearch} searching={state.loading} />
                        <FilterDropdown filters={filterOptions} onFilterChange={onFilterChange} />

                        <div className="tools">
                            <EditMultiple items={state.editMultipleItems} onItemClick={onEditMultipleClick} count={selectedRows.length} />
                            <ColumnPicker items={gridColumns} onItemChange={onColumnChange} />
                        </div>

                        <FilterTagBar filters={state.filters} onRemove={removeFilter} />
                    </div>
                }

                {/* Mobile filter bar */}
                {isMobile && 
                    <div className="mobile-filter-bar">
                        <FilterDropdown filters={filterOptions} onFilterChange={onFilterChange} />
                    </div>
                }

                {/* Grid */}
                <Grid
                    header={
                        <div className="header-row row">
                            <ColumnHeader className="check"><CheckBoxHeaderButton checked={Object.keys(checkboxState).find(k => checkboxState[k])} onClick={toggleAllCheckboxes} /></ColumnHeader>
                            <ColumnHeader className="groupname" sort={sort} onSort={onSort} visibility={visibility}>Group Name</ColumnHeader>
                            <ColumnHeader className="location" sort={sort} onSort={onSort} visibility={visibility}>{locationName}</ColumnHeader>
                            <ColumnHeader className="grouptype" sort={sort} onSort={onSort} visibility={visibility}>Type</ColumnHeader>
                            <ColumnHeader className="groupid" sort={sort} onSort={onSort} visibility={visibility}>Group ID</ColumnHeader>
                            <ColumnHeader className="alerts" sort={sort} onSort={onSort} visibility={visibility}>Alerts</ColumnHeader>
                            <ColumnHeader className="send"></ColumnHeader>
                            <ColumnHeader className="actions"></ColumnHeader>
                        </div>
                    }
                    itemTemplate={g =>
                        <div className="row" key={g.id}>
                            <GridCell className="check"><GuiCheckbox name={"" + g.id} value={checkboxState[g.id]} onChange={updateCheckboxState} /></GridCell>
                            <GridCell className="groupname" visibility={visibility}>
                                {g.name || ""}
                                <span>{g.userCount} {pluralize("Member", g.userCount)}</span>
                            </GridCell>
                            <GridCell className="location" visibility={visibility}>{g.location || ""}</GridCell>
                            <GridCell className="grouptype" visibility={visibility}>{g.groupTypeName || ""}</GridCell>
                            <GridCell className="groupid" visibility={visibility}>{g.groupCode || ""}</GridCell>
                            <GridCell className="alerts" visibility={visibility}>{g.alertCount || ""}</GridCell>
                            <GridCell className="send">
                                <Icon icon="paper-plane" button={true} onClick={e => goToSendAlert(g.id)} />
                            </GridCell>
                            <GridCell className="actions">
                                <i className="far fa-ellipsis-h" onClick={e => updateState("showActionsMenu", g.id)}></i>
                                <Popover show={state.showActionsMenu === g.id} className="no-arrow anchor-right popover-menu actions-menu" onHide={() => updateState("showActionsMenu", null)}>
                                    <div className="menu-item" onClick={e => goToSendAlert(g.id)}>Send Alert</div>
                                    <div className="menu-item" onClick={e => updateState({ showActionsMenu: null, showExportModal: true, currentGroup: g })}>Export</div>
                                    <div className="menu-item" onClick={e => updateState({ showActionsMenu: null, showEditModal: true, currentGroup: g })}>Edit</div>
                                    <div className="menu-item" onClick={e => updateState({ showActionsMenu: null, showDeleteGroupsModal: true, currentGroup: g })}>Delete</div>
                                </Popover>
                            </GridCell>
                        </div>
                    }


                    mobileItemTemplate={g => 
                        <div className="item" key={g.id}>
                            <div className="info">
                                <h6>{g.name || ""}</h6>
                                <p>{g.location}<SeparatorDot />{g.groupTypeName}<SeparatorDot />{g.userCount} {pluralize("Member", g.userCount)}</p>
                            </div>
                            <div className="mobile-actions">
                                <i className="far fa-ellipsis-h" onClick={e => updateState("showActionsMenu", g.id)}></i>
                                <Popover show={state.showActionsMenu === g.id} className="no-arrow anchor-right popover-menu actions-menu" onHide={() => updateState("showActionsMenu", null)}>
                                    <div className="menu-item" onClick={e => goToSendAlert(g.id)}>Send Alert</div>
                                    <div className="menu-item" onClick={e => updateState({ showActionsMenu: null, showExportModal: true, currentGroup: g })}>Export</div>
                                    <div className="menu-item" onClick={e => updateState({ showActionsMenu: null, showEditModal: true, currentGroup: g })}>Edit</div>
                                    <div className="menu-item" onClick={e => updateState({ showActionsMenu: null, showDeleteGroupsModal: true, currentGroup: g })}>Delete</div>
                                </Popover>
                            </div>
                        </div>
                    }
                    mobileItemHeight={65}

                    reset={state.gridReset}
                    pageSize={20}
                    onItemsNeeded={loadRows}
                    onPageChanged={onPageChanged}
                    mobileFooter={count => (!state.loading ? (count + " " + pluralize("Group", state.totalCount)) : "\u00A0")}
                >
                </Grid>
            </Card>


            {/* Edit */}
            {state.showEditModal &&
                <GroupPropertiesModal group={state.currentGroup} onClose={(changed) => updateState({ showEditModal: false, gridReset: state.gridReset + (changed ? 1 : 0)})} />
            }

            {/* Delete */}
            {state.showDeleteGroupsModal &&
                <Modal className="plain-modal no-close">
                    <p>Are you sure you want to permanently remove {state.currentGroup ? "this group" : "these groups"} from the system?</p>
                    <div className="buttons">
                        <GuiButton className="btn-outline" onClick={e => updateState({ showDeleteGroupsModal: false, currentGroup: null })}>Cancel</GuiButton>
                        <GuiButton className="warning" onClick={deleteGroups} loading={state.saving}>Delete {state.currentGroup ? "Group" : selectedRows.length + " Groups"}</GuiButton>
                    </div>
                </Modal>
            }

            {/* Export */}
            {state.showExportModal &&
                <Modal className="plain-modal no-close">
                    <p>Would you like to export people data to excel?</p>
                    <div className="buttons">
                        <GuiButton className="btn-outline" onClick={e => updateState({ showExportModal: false, currentGroup: null })}>Cancel</GuiButton>
                        <GuiButton className="" onClick={exportGroup} loading={state.saving}>Export</GuiButton>
                    </div>
                </Modal>
            }

            {/* Merge */}
            {state.showMergeGroups &&
                <MergeGroupsModal mergeSourceIds={selectedRows} onClose={(changed) => updateState({ showMergeGroups: false, gridReset: state.gridReset + (changed ? 1 : 0) })} />
            }

        </PageLayout>
    );
}