import "../styles/page-layout.scss";
import React, { useState } from "react";
import { SearchBox } from "./search-box";


export default function PageLayout({ title, className, children, tabs, buttonBar, onMobileSearch, searching }) {
    let [showMobileSearch, setShowMobileSearch] = useState(false);

    return (
        <div className={"page-layout " + (className || "")}>
            <div className={"page-layout-header" + (showMobileSearch ? " mobile-search" : "")}>
                {/* Title */}
                <h3>{title}</h3>

                {/* Top Buttons */}
                {buttonBar &&
                    <div className="page-layout-top-buttons">
                        {onMobileSearch && <i className="fal fa-search mobile-only" onClick={() => setShowMobileSearch(true)} />}
                        {buttonBar}
                    </div>
                }

                {showMobileSearch &&
                    <div className="page-layout-mobile-search-bar">
                        <SearchBox onSearchChange={onMobileSearch} searching={searching} />
                        <span className="cancel" onClick={() => { setShowMobileSearch(false); onMobileSearch("") }}>Cancel</span>
                    </div>
                }

                {/* Tabs */}
                {tabs ?
                    <div className="page-layout-tab-bar">
                        <div className="page-layout-tabs">
                            {tabs}
                        </div>
                    </div>
                    :
                    <div style={{ "clear": "both" }}></div>
                }
            </div>

            {/* Content */}
            <div className="page-layout-body">
                {children}
            </div>
        </div>
    );
}

// Tabs example
//tabs = {<>
//            <TabItem dataQa="all-tab" tab={Path.People} currentTab={tab} onClick={actions.people.changeTab}>All</TabItem>
//            <TabItem dataQa="managers-tab" tab={Path.PeopleManagers} currentTab={tab} onClick={actions.people.changeTab}>Managers</TabItem>
//            <TabItem dataQa="admin-tab" tab={Path.PeopleAdmin} currentTab={tab} onClick={actions.people.changeTab}>Admin</TabItem>
//        </>}