import '../styles/add-person.scss';
import React, { useState } from 'react';
import PageLayout from '../components/page-layout';
import actions from '../actions/actions';
import { useSelector } from 'react-redux';
import Card from '../components/card';
import ExpandPanel from '../components/expand-panel';
import ValidationContext, { useValidation } from '../components/global-ui/validation';
import { GuiButton } from '../components/global-ui/gui-button';
import { GuiTextbox } from '../components/global-ui/gui-textbox';
import { useUpdateState } from '../utilities/utilities';
import { GuiSelect } from '../components/global-ui/gui-select';
import { Icon } from '../components/icon';
import navigate from '../utilities/navigation';
import { Path, USStates } from '../utilities/constants';



export default function AddPersonPage() {
    let userTypeOptions = useSelector(store => actions.user.loadUserTypes()) || [];
    let isMobile = useSelector(store => store.page.isMobile);
    let [step, setStep] = useState(0);
    let [state, updateState] = useUpdateState({});
    let [person, updatePerson] = useUpdateState({});
    let contactMethods = person.contactMethods || [{}, {}];
    let customGroups = person.customGroups || [0];

    let validation = useValidation(validate, []);

    let step1Valid = false;
    let step2Valid = false;
    let step3Valid = false;

    function changeStep(step) {
        //if (step > 0 && !howTabValid)
        //    return;
        //if (step > 1 && !whoTabValid)
        //    return;
        //if (step > 2 && !messageTabValid)
        //    return;

        setStep(step);
    }

    function addContactMethod() {
        let methods = [...contactMethods, {}];
        updatePerson("contactMethods", methods);
    }

    function updateContact(cm, name, value) {
        cm[name] = value;
        updatePerson(person);
    }

    function removeContact(cm) {
        let methods = contactMethods.filter(x => x !== cm);
        updatePerson("contactMethods", methods);
    }

    function addGroup() {
        let groups = [...customGroups, {}];
        updatePerson("customGroups", groups);
    }

    function updateGroup(index, value) {
        //let groups = [...customGroups];
        //groups[index] = value;
        //updatePerson("customGroups", groups);
    }

    function removeGroup(index) {
        let groups = customGroups.filter((x, i) => i !== index);
        updatePerson("customGroups", groups);
    }


    function validate() {
        let errors = {};

        return errors;
    }

    async function savePerson() {
        if (!validation.allValid())
            return validation.setShow(true);

        updateState("saving", true);

        let result = await actions.people.savePerson(person);
        if (result && result.success)
            navigate(Path.People);
        else
            updateState("saving", false);
    }


    return (
        <ValidationContext.Provider value={validation}>
            <PageLayout title="Add User" className="add-person">
                <Card>

                    {/* User Information */}
                    <ExpandPanel title="User Information" expanded={step === 0} complete={step1Valid} onHeaderClick={() => changeStep(0)} hasError={validation.getDisplayError("tab0")}>
                        <GuiTextbox label="First Name" name="firstName" value={person.firstName} onChange={updatePerson} />
                        <GuiTextbox label="Last Name" name="lastName" value={person.lastName} onChange={updatePerson} />
                        <GuiSelect label="User Type" name="userType" value={person.userType} onChange={(name, opt) => updatePerson(name, opt.value)} options={userTypeOptions} />
                        <GuiTextbox label="ID" name="uniqueId" value={person.uniqueId} onChange={updatePerson} format="digits" />
                        <GuiTextbox label="Username" name="username" value={person.username} onChange={updatePerson} />
                        <GuiTextbox label="Password" name="password" value={person.password} onChange={updatePerson} />
                        <GuiButton onClick={() => changeStep(1)}>Next</GuiButton>
                    </ExpandPanel>

                    {/* Contact Information */}
                    <ExpandPanel title="Contact Information" expanded={step === 1} complete={step2Valid} onHeaderClick={() => changeStep(1)} hasError={validation.getDisplayError("tab0")}>
                        <GuiTextbox label="Address" name="address" value={person.address} onChange={updatePerson} />
                        <GuiTextbox label="City" name="city" value={person.city} onChange={updatePerson} />
                        <GuiSelect label="State" name="state" value={person.state} onChange={updatePerson} options={USStates} />
                        <GuiTextbox label="Zip" name="zip" value={person.zip} onChange={updatePerson} />

                        {contactMethods.map((cm, i) => <ContactMethod method={cm} updateContact={updateContact} removeContact={removeContact} index={i} />)}

                        <GuiButton className="btn-outline" onClick={addContactMethod}><Icon icon="plus" weight="400" />Add</GuiButton>
                        <GuiButton onClick={() => changeStep(2)}>Next</GuiButton>
                    </ExpandPanel>

                    {/* Group Information */}
                    <ExpandPanel title="Group Information" expanded={step === 2} complete={step3Valid} onHeaderClick={() => changeStep(2)} hasError={validation.getDisplayError("tab0")}>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>

                        {customGroups.map((id, i) => <CustomGroup groupIds={customGroups} updateGroup={updateGroup} removeGroup={removeGroup} index={i} />)}
                        <GuiButton className="btn-outline" onClick={addGroup}><Icon icon="plus" weight="400" />Add</GuiButton>
                    </ExpandPanel>
                </Card>

                {/* Bottom Buttons */}
                <div className="buttons">
                    <GuiButton className="btn-outline" onClick={() => navigate(Path.People)}>Cancel</GuiButton>
                    <GuiButton onClick={savePerson} loading={state.saving}>Add User</GuiButton>
                </div>
            </PageLayout>
        </ValidationContext.Provider>

    );
}


function ContactMethod({ method, updateContact, removeContact, index }) {
    function update(name, value) {
        updateContact(method, name, value);
    }

    return (
        <div className="contact-method">
            <div>Contact method {index + 1}</div>
            <GuiTextbox label="Phone Number" name="phone" value={method.phone} onChange={update} format="phone" />
            <GuiTextbox label="Ext" name="ext" value={method.ext} onChange={update} format="digits" />
            <GuiTextbox label="Description" name="description" value={method.description} onChange={update} />
            <Icon icon="xmark-large" onClick={() => removeContact(method)} />
        </div>
    );
}


function CustomGroup({ groupIds, updateGroup, removeGroup, index }) {
    let groupId = groupIds[index];
    let groupOptions = useSelector(store => actions.groups.loadGroupOptions()) || [];
    groupOptions = groupOptions.filter(g => g.value === groupId || !groupIds.includes(g.value));


    return (
        <div className="custom-group">
            <div>Custom Group {index + 1}</div>
            <GuiSelect label="" name="group" value={groupId} onChange={(name, value) => updateGroup(index, value)} options={groupOptions} />
            <Icon icon="xmark-large" onClick={() => removeGroup(index)} />
        </div>
    );
}
