import { apiRequestWithChecks } from "./api";


export const report = {
    getRecentActivity: function (token, start, end, type, sort, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("PUT", "/api/report/recent-activity", {
            "Authentication": "Bearer " + token
        },
            {
                Start: start || null,
                End: end || null,
                ActivityType: type || null,
                Sort: sort,
                PageNum: pageNum,
                PageSize: pageSize,
                SkipTotal: skipTotal
            });
    }
}