import "../styles/top-bar.scss";
import React, { useRef } from "react";
import { dispatch } from "../redux/redux-config";
import { useSelector } from "react-redux";
import actions from '../actions/actions';
import Popover from "./popover";
import { useEffect } from "react";
import { useUpdateState } from "../utilities/utilities";
import navigate from "../utilities/navigation";
import { Path } from '../utilities/constants';


export default function TopBar() {
    let user = useSelector(store => store.user);
    let isSidebarExpanded = useSelector(store => store.page.sidebarExpanded);
    let updateVersion = useSelector(store => store.page.updateVersion);
    let [state, updateState] = useUpdateState({});
    let nameRef = useRef();
    let { showHelpMenu, showSettingsMenu, showUserMenu } = state;
    let showUpdateAvailable = (updateVersion && updateVersion !== state.updateVersionDismissed);

    if (user.loaded === undefined)
        setTimeout(actions.user.getUserInfo, 0);


    useEffect(() => {
        if (nameRef.current) {
            nameRef.current.style.visibility = "hidden";
            for (let i = 35; i > 0; i--) {
                nameRef.current.style.fontSize = i + "px";
                if (nameRef.current.scrollWidth <= nameRef.current.clientWidth)
                    break;
            }
            nameRef.current.style.visibility = "visible";
        }

        function onResize() {
            updateState({});
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [user.customerName, window.outerWidth, state.logoLoaded]);


    function onImageLoad() {
        updateState({ logoLoaded: true }); // Whether they have a logo is partially determined by whether the url exists
    }

    function onImageError() {
        updateState({ logoLoaded: false });
    }

    function goTo(e, path) {
        e.preventDefault();
        e.stopPropagation();
        updateState({ showHelpMenu: false, updateVersionDismissed: updateVersion });
        navigate(path);
    }

    function goToTraining(e) {
        e.preventDefault();
        e.stopPropagation();
        window.open(user.trainingVideoUrl);
    }


    return (
        <div className="top-bar">
            <div className="mobile-menu-button" onClick={() => dispatch("TOGGLE_SIDE_BAR", !isSidebarExpanded)}><i className="fas fa-solid fa-bars"></i></div>

            <div className="client-logo" style={{ display: state.logoLoaded ? undefined : "none" }}>
                <div>
                    <img src={user.logoUrl} onLoad={onImageLoad} onError={onImageError} alt="" />
                </div>
            </div>

            {!state.logoLoaded &&
                <div className="client-name" ref={nameRef}>
                    {user.customerName}
                </div>
            }

            <div className="right-buttons">
                {/* Help */}
                <div className="help" onClick={() => updateState({ showHelpMenu: true })}>
                    <i className="fas fa-question-circle"></i>
                    <Popover show={showHelpMenu && !showUpdateAvailable} onHide={() => updateState({ showHelpMenu: false })}>
                        <div className="menu-item" onClick={goToTraining}>Training Videos</div>
                        <div className="menu-item" onClick={e => goTo(e, Path.Help)}>Contact Us</div>
                    </Popover>
                    <Popover show={showUpdateAvailable} onHide={() => updateState({ updateVersionDismissed: updateVersion })}>
                        <div className="menu-item" onClick={e => goTo(e, Path.Help)}>An Update is Available!</div>
                    </Popover>
                </div>

                {/* Settings */}
                {/*<div className="settings-cog" onClick={() => updateState({ showSettingsMenu: !showSettingsMenu })}>*/}
                {/*    <i className="fa-solid fa-cog"></i>*/}
                {/*    <Popover show={showSettingsMenu} onHide={() => updateState({ showSettingsMenu: false })}>*/}
                {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsVoice)}>Voice Preferences</div>*/}
                {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsSms)}>SMS Preferences</div>*/}
                {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsLanguage)}>Language Settings</div>*/}
                {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsContact)}>Contact Preferences</div>*/}
                {/*        <div className="menu-item" onClick={e => goTo(e, Path.SettingsPhoneCode)}>Phone Code</div>*/}
                {/*    </Popover>*/}
                {/*</div>*/}

                {/* Account */}
                <div className="user-icon" onClick={() => updateState({ showUserMenu: !showUserMenu })}>
                    <span id="account">{(user.firstName?.substr(0, 1) + user.lastName?.substr(0, 1)) || "\u00A0"}</span>
                    <Popover show={showUserMenu} onHide={() => updateState({ showUserMenu: false })}>
                        {/*<div className="menu-item" onClick={e => goTo(e, Path.Profile)}>My Profile</div>*/}
                        {/*{!user.isAmplify &&*/}
                        {/*    <div className="menu-item" onClick={e => goTo(e, Path.MyAccount)}>My Account</div>*/}
                        {/*}*/}
                        <div className="menu-item" onClick={actions.user.logout}>Log Out</div>
                    </Popover>
                </div>
            </div>
        </div>
    );
}