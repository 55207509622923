import { apiRequestWithChecks } from "./api";


export const alerts = {
    list: function (token, status, search, pageNum, pageSize, filters, sort, skipTotal) {
        return apiRequestWithChecks("POST", "/api/alerts/list", {
            "Authentication": "Bearer " + token
        },
            {
                status: status,
                PageNum: pageNum,
                PageSize: pageSize,
                search: search,
                filters: filters,
                sort: sort,
                skipTotal: skipTotal
            });
    },

    save: function (token, model) {
        return apiRequestWithChecks("POST", "/api/alerts", {
            "Authentication": "Bearer " + token
        }, model);
    },

    getDetails: function (token, alertId) {
        return apiRequestWithChecks("GET", "/api/alerts?id=" + alertId, {
            "Authentication": "Bearer " + token
        });
    },

    deleteAlert: function (token, alertId) {
        return apiRequestWithChecks("DELETE", "/api/alerts?id=" + alertId, {
            "Authentication": "Bearer " + token
        });
    },

    templates: function (token, alertType) {
        return apiRequestWithChecks("GET", "/api/alerts/templates?alertType=" + alertType, {
            "Authentication": "Bearer " + token
        });
    },
}