import React, { useMemo } from "react";
import ReactAudioPlayer from 'react-audio-player';


export default function AudioPlayer({ src, autoPlay }) {
    let audioSrc = useMemo(() => (src && typeof src !== 'string') ? URL.createObjectURL(src) : src, [src]);

    return (
        <ReactAudioPlayer src={audioSrc} autoPlay={autoPlay} controls />
    );
}