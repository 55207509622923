import { apiRequestWithChecks } from "./api";


export const people = {
    getPeopleGrid: function (token, search, type, groupIds, criterias, sort, pageNum, pageSize, skipTotal) {
        return apiRequestWithChecks("POST", "/api/people/grid", {
            "Authentication": "Bearer " + token
        },
            {
                Search: search || "",
                UserTypeId: type || null,
                GroupIds: groupIds || null,
                Criteria: criterias || null,
                Sort: sort,
                PageNum: pageNum,
                PageSize: pageSize,
                SkipTotal: skipTotal
            });
    },

    getPersonDetails: function (token, personId) {
        return apiRequestWithChecks("GET", "/api/people/details?id=" + personId, {
            "Authentication": "Bearer " + token
        });
    },

    save: function (token, person) {
        return apiRequestWithChecks("PUT", "/api/people", {
            "Authentication": "Bearer " + token
        },
            {
                Person: person
            });
    },

    addPeopleToGroup: function (token, groupId, personIds) {
        return apiRequestWithChecks("POST", "/api/people/group", {
            "Authentication": "Bearer " + token
        },
            {
                GroupId: groupId,
                PersonIds: personIds
            });
    },

    deletePeople: function (token, personIds) {
        return apiRequestWithChecks("DELETE", "/api/people", {
            "Authentication": "Bearer " + token
        },
            {
                PersonIds: personIds
            });
    },

    setPriority: function (token, personIds, high) {
        return apiRequestWithChecks("POST", "/api/people/priority", {
            "Authentication": "Bearer " + token
        },
            {
                PersonIds: personIds,
                high: high
            });
    },
}